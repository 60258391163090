<template>
  <div class="subcooperation">
    <div class="sub-banner bg" />
    <div class="wrapper">
      <div class="bread">
        <div class="icon"><i class="el-icon-caret-right" /></div>
        <div class="text">您的位置：首页  <i>></i>  <span>合作共赢</span></div>
      </div>
      <div class="sub-menu">
        <a href="/baseHome/about" title="公司简介">公司简介</a>
        <a href="/baseHome/culture" title="企业文化">企业文化</a>
        <a href="/baseHome/honor" title="荣誉资质">荣誉资质</a>
        <a href="/baseHome/cooperation" title="合作共赢" class="on">合作共赢</a>
        <a href="/baseHome/joinus" title="加入我们">加入我们</a>
      </div>
    </div>
    <div class="sub-title" style="letter-spacing:3px;">为什么选择山水物联？</div>
    <div class="wrapper cooperation">
      <dl>
        <dt><img src="../../assets/images/co1.jpg"></dt>
        <dd>巨大行业前景</dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/co2.jpg"></dt>
        <dd>多年实体物流经营经验</dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/co3.jpg"></dt>
        <dd>省内首批网络货运试点企业</dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/co4.jpg"></dt>
        <dd>国家政策大力支持</dd>
      </dl>
    </div>
    <div class="sub-title" style="letter-spacing:3px;">我们能为你提供什么？</div>
    <div class="wrapper cooperation1">
      <dl>
        <dt><img src="../../assets/images/co5.jpg"></dt>
        <dd><h3>免费建平台</h3><div class="cont">
          <span>●</span> 平台接入指南<br>
          <span>●</span> 平台推广方案<br>
          <span>●</span> 平台建设运营<br>
          <span>●</span> 平台运营方案<br>
          <span>●</span> 提供全流程平台建设
          服务</div></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/co6.jpg"></dt>
        <dd><h3>免费申报资质</h3><div class="cont">
          <span>●</span> 从政策解读、材料准备、方案撰写到现场答辩<br>
          <span>●</span> 专门的客户经理全程协助资质申报<br>
          <span>●</span> 复杂的事情简单化 山水物联免费为加入者提供申报服务</div></dd>
      </dl>
      <dl>
        <dt><img src="../../assets/images/co7.jpg"></dt>
        <dd><h3>免费建平台</h3><div class="cont"><span>●</span> 协助品牌推广，运营策略指导，项目实施服务<br>
          <span>●</span> 山水物联免费输出管理，实现快速盈利<br>
          <span>●</span> 助力中小企业飞速成长，精确定位无车承运人</div></dd>
      </dl>
    </div>

    <div class="cooperation2">
      <div class="wrapper">
        <div class="sub-title" style="letter-spacing:3px;">我们有哪些优势？</div>
        <dl>
          <dt><img src="../../assets/images/coicon1.png"></dt>
          <dd><h3>品牌优势</h3><div class="cont">山水物联隶属山水集团，拥有强有力的AAAA级实体物流经验作支撑。</div></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/coicon2.png"></dt>
          <dd><h3>成本优势</h3><div class="cont">国家特批无车承运企业、国家政策大力支持；地区政府给予税务优惠政策，税点更低</div></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/coicon3.png"></dt>
          <dd><h3>管理优势</h3><div class="cont">成熟的管理团队、有效的绩效管理体系、完善的管理培训系统，免费为客户提供平台培训、管理服务</div></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/coicon4.png"></dt>
          <dd><h3>资源优势</h3><div class="cont">多年的实体物流运输经验、积累广泛资源；云平台信息共享</div></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/coicon5.png"></dt>
          <dd><h3>技术优势</h3><div class="cont">拥有独立的研发技术团队，平台搭建日趋完善，适用于各类企业的物流管理需要</div></dd>
        </dl>
        <dl>
          <dt><img src="../../assets/images/coicon6.png"></dt>
          <dd><h3>服务优势</h3><div class="cont">优异服务团队，客服实时在线，一对一服务，响应速度快</div></dd>
        </dl>
      </div>
    </div>

    <div class="wrapper cooperation3">
      <div class="sub-title" style="letter-spacing:3px;">合作方式</div>
      <div class="text">加入人群：物流企业、货主企业、个人（自然人）。<br>
        总部为城市合伙人提供战略支持、模式指导、宣传支持和资源匹配等四大支持方式，助力合伙人迅速开拓当地市场。</div>
      <dl>
        <dt>
          <a class="bbbb"><img src="../../assets/images/coff1.png"></a>
          <a class="aaaa"><img src="../../assets/images/coff1.png"></a>
        </dt>
        <dd><h3>地级市城市合伙人</h3>整合地方资源，精耕城市渠道发展；<br>
          在物流、大宗货物类等行业具有一定资源的公司、个人；</dd>
      </dl>
      <dl>
        <dt>
          <a class="bbbb"><img src="../../assets/images/coff2.png"></a>
          <a class="aaaa"><img src="../../assets/images/coff2.png"></a>
        </dt>
        <dd><h3>平台合作人</h3>打造智慧物流大平台，资源整合助力产业升级<br>
          具有较强商务推广能力的公司、个人，<br>
          且想进入网络货运行业；</dd>
      </dl>
      <dl>
        <dt>
          <a class="bbbb"><img src="../../assets/images/coff3.png"></a>
          <a class="aaaa"><img src="../../assets/images/coff3.png"></a>
        </dt>
        <dd><h3>业务人员</h3>具有自由时间，能够履行合伙人的基本职责的公司、个人。</dd>
      </dl>
    </div>

    <div class="cooperation4">
      <div class="wrapper">
        <div class="sub-title1" style="letter-spacing:3px; padding-top: 60px;">合作流程</div>
        <div class="cooperation5">
          <div class="num1"><span>1</span></div>
          <div class="num1" style="left:260px;"><span>2</span>
            <div class="wave1">
              <div class="w1" />
              <div class="w2" />
              <div class="w3" />
              <div class="w4" />
            </div>
          </div>
          <div class="num1" style="left:476px;"><span>3</span></div>
          <div class="num1" style="left:692px;"><span>4</span>
            <div class="wave1">
              <div class="w1" />
              <div class="w2" />
              <div class="w3" />
              <div class="w4" />
            </div>
          </div>
          <div class="num1" style="left:908px;"><span>5</span></div>
          <div class="num1" style="left:1124px;"><span>6</span>
            <div class="wave1">
              <div class="w1" />
              <div class="w2" />
              <div class="w3" />
              <div class="w4" />
            </div>
          </div>
          <div class="text">申请</div>
          <div class="text" style="top:85px; left:240px;">审核资格</div>
          <div class="text" style="left:475px;">洽谈</div>
          <div class="text" style="top:85px; left:690px;">签约</div>
          <div class="text" style="left:905px;">培训</div>
          <div class="text" style="top:85px; left:1102px;">开展业务</div>
          <div class="colc"><img src="../../assets/images/colc1.png"></div>
          <div class="colc" style="top:196px; left:225px;"><img src="../../assets/images/colc2.png"></div>
          <div class="colc" style="top:5px; left:445px;"><img src="../../assets/images/colc3.png"></div>
          <div class="colc" style="top:196px; left:658px;"><img src="../../assets/images/colc4.png"></div>
          <div class="colc" style="top:5px; left:875px;"><img src="../../assets/images/colc5.png"></div>
          <div class="colc" style="top:196px; left:1090px;"><img src="../../assets/images/colc6.png"></div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

export default {
  name: '',
  components: {
  },
  data() {
    return {

    }
  },

  mounted() {

  },
  methods: {

  }
}
</script>

<style lang="scss">
@import "./../../assets/scss/sub.scss";
.subcooperation {
  .bg{background:url(./../../assets/images/cobanner.jpg) }
  .cooperation{ overflow: hidden;
    dl{ float: left; width: 286px; margin: 0 15px 0 0; border: #e9ebea solid 1px;
      dt{ width: 286px; height: 150px; overflow: hidden;  text-align: center;}
      dt img{width: 286px; height: 150px;  transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
      &:hover dt img{width: 310px; height: 163px; margin-top: -6px;margin-left: -12px;}
      dd{ text-align: center; height: 80px; line-height: 75px; font-size: 18px; color: #333;}
    }
    dl:nth-child(4){margin-right: 0;}
  }
  .cooperation1{ overflow: hidden;
    dl{ float: left; width: 381px; margin: 0 15px 0 0; border: #e9ebea solid 1px;
      dt{ float: left; width: 200px; height: 276px; overflow: hidden;  text-align: center;}
      dt img{width: 200px; height: 276px;  transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
      &:hover dt img{width: 220px; height: 304px; margin-top: -14px;margin-left: -10px;}
      dd{ float: left; height: 80px; font-size: 18px; color: #333; padding:6px 0 0 15px; width: 160px;
        h3{  color: #0062db; font-size: 18px; line-height:44px;}
        .cont{ line-height: 27px; color: #666; font-size: 15px;
          span{color: #0062db;}
        }
      }
    }
    dl:nth-child(3){margin-right: 0;}
  }
  .cooperation2{ overflow: hidden; background: #f5f5f5; margin-top: 80px; padding-bottom: 60px;
    dl{ float: left; width: 592px; height: 141px; margin: 0 12px 12px 0; border: #e9ebea solid 1px; background: #fff;
      dt{ float: left; width: 60px; height: 60px; margin:35px 0 0 25px; overflow: hidden;  text-align: center;transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;}
      &:hover dt{ margin-top: 45px;}
      dd{ float: left; height: 80px; font-size: 18px; color: #333; padding:20px 0 0 20px; width: 450px;
        h3{ font-weight: normal; color: #111; font-size: 20px; line-height:44px; }
        .cont{ line-height: 28px; color: #666; font-size: 16px;
        }
      }
    }
    dl:nth-child(odd){margin-right: 0;}
  }
  .cooperation3{ overflow: hidden;
    .text{ text-align: center; line-height: 30px; color: #666; font-size: 16px; margin-top: -17px;}
    dl{ float: left; width: 33.33%; background: #fff; text-align: center; padding:15px 0 50px;
      dt{position: relative; overflow: hidden;  text-align: center; width: 152px; height: 129px;margin:35px auto 0; }
      dt a{ position: absolute; display: block;transition:all 1s; -webkit-transition:all 1s; -moz-transition:all 1s; -ms-transition:all 1s; -o-transition:all 1s; transition:all 1s; backface-visibility:hidden; -webkit-backface-visibility:hidden; }
      &:hover dt .aaaa{transform:rotateY(180deg) ; -webkit-transform:rotateY(180deg) ; -moz-transform:rotateY(180deg) ; -ms-transform:rotateY(180deg) ; -o-transform:rotateY(180deg);}
      &:hover dt .bbbb{transform:rotateY(360deg) ; -webkit-transform:rotateY(360deg) ; -moz-transform:rotateY(360deg) ; -ms-transform:rotateY(360deg) ; -o-transform:rotateY(360deg);}
      // transition: all .5s; -webkit-transition: all .5s; -moz-transition: all .5s; -ms-transition: all .5s; -o-transition: all .5s;
      // &:hover dt{ margin-top: 45px;}
      dd{ font-size: 18px; width: 320px; margin:0 auto; padding:15px 0 0 20px; color: #666; font-size: 16px; line-height:30px;
        h3{ color: #414141; font-size: 18px; line-height:44px; }
      }
    }
    dl:nth-child(odd){margin-right: 0;}
  }
  .cooperation4{height: 496px;background:url(./../../assets/images/cobg.jpg) center;
    .cooperation5{background:url(./../../assets/images/cobg1.jpg) center repeat-x; height: 300px;position: relative;
      .num1{ position: absolute; top: 131px; left: 44px;
        span{position: absolute; left: 0; top: 0;background: #fff;width: 36px; line-height: 36px; text-align: center;border-radius: 50%;color: #006cdb; font-size: 30px; font-weight: bold; z-index: 1;}
      }
      .text{position: absolute; top: 185px; left: 42px; font-size: 20px; color: #fff;}
      .colc{ position: absolute; top: 5px; left: 15px;transition: all 1s; -webkit-transition: all 1s; -moz-transition: all 1s; -ms-transition: all 1s; -o-transition: all 1s;}

      // .colc:hover{-webkit-transform:rotate(360deg);-moz-transform:rotate(360deg);-ms-transform:rotate(360deg);-o-transform:rotate(360deg);transform:rotate(360deg);}
    }

  }

}
</style>
